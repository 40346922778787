import styled from 'styled-components';
import {ReactComponent as BillIconSVG} from '../../assets/billSide.svg'
import {ReactComponent as TrashIconSVG} from "../../assets/trash.svg"
import {ReactComponent as arrowRightDepositIconSVG} from "../../assets/arrowRightDeposit.svg"

export const ArrowRightDepositIcon = styled(arrowRightDepositIconSVG)``


export const BillIcon = styled(BillIconSVG)`

fill: var(--cian);
width: 16px;

`

export const TrashIcon = styled(TrashIconSVG)`

fill: var(--cian);
width: 16px;

`

export const Container = styled.div`

height: 100vh;


`

export const Content = styled.main`

grid-area: content;
display: flex;
flex-direction: column;
background-color: var(--background);


`

export const ContentWrapper = styled.div`

padding: 32px;

`

export const Action = styled.div`

display: flex;
flex-direction: column;

@media (min-width: 720px){
    flex-direction: row;
    justify-content: space-between;
}

`

export const Search = styled.div`

max-width: 592px;

> span {

    font-size: 16px;
    font-family: var(--regular);
    color: var(--gray);
}
`

export const ButtonWrapper = styled.div`

width: 100%;
margin: 32px 0 0 0;

@media (min-width: 720px){
    width: 242px;
    margin: 0;
}

`

export const InputWrapper = styled.div`

margin-top: 37px;
flex: 1;
border: 1px solid var(--gray-light-order);
border-radius: 8px;
padding: 14px 16px;


> input {
    background: none;
    border-style: none;
    width: 100%;
    outline: 0;
}

`

export const Table = styled.div`

    flex: 1;
    margin-top: 32px;

`


export const TableWrapper = styled.div`

  border-radius: 8px;
  background-color: var(--white);
  width: calc(100vw - 64px);
  overflow: auto;

  @media (min-width: 720px){
    width: calc(100vw - 64px - 256px);
  }

  @media (min-width: 1280px){
      width: 100%;
  }


> table {

    width: 100%;

    > tbody {

    border-radius: 8px;
    border: none;
    border-collapse: collapse;

    > tr {
        position: relative;

        &:first-child {

            &::after {
                content: '';
                height: 16px;
                width: 4px;
                background-color: var(--cian);
                position: absolute;
                bottom: 0;
                top: 0;
                margin-top: auto;
                margin-bottom: auto;
                left: 0;
            }
        }


        border-bottom: 1px solid var(--gray-light-line);

        > th {
            text-align: start;
            font-family: var(--semiBold);
            font-size: 14px;
            color: var(--gray);
            padding: 16px;
            border-bottom: 1px solid  var(--gray-light-line);

            @media (min-width: 720px){
                font-size: 16px;
                padding: 21px 32px;
            }
        }



        > td {
            padding: 6px 16px;
            font-family: var(--regular);
            font-size: 10px;
            color: var(--gray-light-text);
            cursor: pointer;
            border-bottom: 1px solid  var(--gray-light-line);



                @media (min-width: 720px){
                    font-size: 14px;
                    padding: 12px 32px;

                }

            &:last-child {
                width: 20%;
            }

            &:nth-child(2) {
                width: 50%;

            }

            }
          }

    }

        }


`

export const ButtonWrapperTable = styled.button`

border-style: none;
height: 32px;
width: 32px;
background-color: var(--gray-light-05);
display: flex;
align-items: center;
justify-content: center;
border-radius: 8px;

`

export const RedirectWrapperButton = styled.div`

margin-left: 72px;
border-style: none;
background-color: none;
display: flex;
align-items: center;

> span {
    color: var(--cian);
    text-decoration: underline;
    margin-right: 18px;
    font-size: 16px;
    font-family: var(--regular);
}

> svg {
    color: var(--cian);
}

`

export const ButtonsTableWrapper = styled.div`

display: flex;

`
