import styled from "styled-components";
import { ReactComponent as ArrowPageLeftIconSVG } from "../../assets/arrowPageLeft.svg";

export const ArrowPageLeftIcon = styled(ArrowPageLeftIconSVG)`
  fill: var(--cian);
  width: 16px;
`;

export const Container = styled.div`
  padding: 32px;
  background-color: var(--background);
`;

export const Content = styled.main`
  grid-area: content;
  display: flex;
  flex-direction: column;
`;

export const Form = styled.form`
  max-width: 460px;
`;

export const HeaderForm = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 44px;
`;

export const GoBackButton = styled.button`
  border-style: none;
  margin-right: 24px;
  height: 40px;
  width: 40px;
  background: var(--white);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 15px -5px rgba(0, 0, 0, 0.2);
`;

export const FormTitle = styled.h2`
  font-size: 24px;
  font-family: var(--bold);
  color: var(--gray-dark);
`;

export const BoxInfoWrapper = styled.div`
  max-width: 480px;
  background-color: var(--confirmBackground);
  padding: 32px;
  display: flex;
  flex-direction: column;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 24px;
  max-width: 480px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Name = styled.span`
  font-size: 16px;
  font-family: var(--regular);
  color: var(--modalTitle);
  margin-bottom: 16px;
`;

export const Value = styled.span`
  font-size: 29px;
  font-family: var(--semiBold);
  color: var(--gray-dark);
  margin-bottom: 16px;
`;

export const DataWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  > span {
    font-size: 12px;
    font-family: var(--bold);
    color: var(--cian);
    margin-right: 7px;
  }
`;
export const Key = styled.p`
  font-size: 12px;
  font-family: var(--regular);
  color: var(--modalTitle);
`;

export const Date = styled.div`
  font-size: 12px;
  font-family: var(--regular);
  color: var(--cian);
`;

export const BoxWrapper = styled.div`
  max-width: 480px;
  background-color: var(--white);
  padding: 32px;
  box-shadow: 0px 2px 15px -5px rgba(0, 0, 0, 0.2);
`;

export const LabelInput = styled.p`
  font-size: 16px;
  font-family: var(--semiBold);
  color: var(--gray-dark);
  margin-bottom: 16px;
`;

export const InvoiceCode = styled.span`
  font-size: 16px;
  font-family: var(--semiBold);
  color: var(--modalTitle);
  margin-bottom: 16px;
`;

export const TextAreaInput = styled.textarea`
  width: 100%;
  height: 124px;
  resize: none;
  border: 1px solid var(--gray-light-line);
  border-radius: 4px;
  padding: 16px 14px;
  font-family: var(--semiBold);
  font-size: 14px;
  outline: none;
  margin-bottom: 16px;
`;

export const BoxContentWrapper = styled.div`

display: flex;
justify-content: space-between;


`

export const WrapperHeader = styled.div`

margin-bottom: 44px;

`
