import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/button";
import { Card } from "../../components/card";
import { StepHeader } from "../../components/stepHeader";
import {
  Container,
  Content,
  CardsWrapper,
  Table,
  TableWrapper,
  ActionStatus,
  PaginationWrapper,
  ArrowPageRightIcon,
  ArrowPageLeftIcon,
  WrapperHeader,
  ButtonWrapper,
} from "./styles";

export const DepositInvoicesList = () => {

  const navigate = useNavigate()
  const handleGoBack = () => {
    navigate(-1)
  }

  const mockData: Array<{
    date: string;
    value: string;
    status: "pending" | "payed" | "delayed",
    title: string;
  }> = [
    {
      date: "21/02/2022",
      value: "R$ 32,30",
      status: "payed",
      title: "PAGO"
    },
    {
      date: "21/02/2022",
      value: "R$ 32,30",
      status: "pending",
      title: "PENDENTE"
    },
    {
      date: "21/02/2022",
      value: "R$ 32,30",
      status: "delayed",
      title: "ATRASADOS"
    },
    {
      date: "21/02/2022",
      value: "R$ 32,30",
      status: "payed",
      title: "PAGO"
    },
    {
      date: "21/02/2022",
      value: "R$ 32,30",
      status: "pending",
      title: "PENDENTE"
    },
    {
      date: "21/02/2022",
      value: "R$ 32,30",
      status: "delayed",
      title: "ATRASADOS"
    },
    {
      date: "21/02/2022",
      value: "R$ 32,30",
      status: "payed",
      title: "PAGO"
    },
    {
      date: "21/02/2022",
      value: "R$ 32,30",
      status: "payed",
      title: "PAGO"
    },
    {
      date: "21/02/2022",
      value: "R$ 32,30",
      status: "payed",
      title: "PAGO"
    },
    {
      date: "21/02/2022",
      value: "R$ 32,30",
      status: "payed",
      title: "PAGO"
    },
    {
      date: "21/02/2022",
      value: "R$ 32,30",
      status: "pending",
      title: "PENDENTE"
    },
    {
      date: "21/02/2022",
      value: "R$ 32,30",
      status: "pending",
      title: "PENDENTE"
    },
  ]

  return (
    <Container>
      <Content>
        <WrapperHeader>
          <StepHeader handleGoBackFunction={handleGoBack} title="Boletos de depósito" />
          <ButtonWrapper>
          <Button
                  handleFunction={() => {}}
                  title="Gerar novo boleto"
          />
          </ButtonWrapper>
        </WrapperHeader>


        <Table>
          <TableWrapper>
            <table>
              <tr>
                <th>Data da emissão</th>
                <th>Valor</th>
                <th>Status</th>
              </tr>
              {mockData.map((item) => {
                return (
                  <tr>
                    <td>{item.date}</td>
                    <td>{item.value}</td>
                    <td>
                      <ActionStatus status={item.status}>
                        <span>{item.title}</span>
                      </ActionStatus>
                    </td>
                  </tr>
                );
              })}
            </table>
          </TableWrapper>
        </Table>
        <PaginationWrapper>
          <ArrowPageLeftIcon />
          <span className="active">1</span>
          <span>2</span>
          <span>3</span>
          <span>4</span>
          <span>...</span>
          <span>24</span>
          <ArrowPageRightIcon />
        </PaginationWrapper>
      </Content>
    </Container>
  );
};
