interface PropsSkeleton {
  isActive?: boolean;
  repeat?: number;
  space?: number;
}

export function Skeleton({ isActive, repeat = 1, space = 0 }: PropsSkeleton) {
  if (!isActive) {
    return null;
  }

  const skeletons = [];
  for (let i = 0; i < repeat; i+=1) {
    skeletons.push(i);
  }

const htmls = skeletons.map((sk) => (
    <div key={sk} className="w-full mx-auto mb-8">
    <div className="animate-pulse flex space-x-4">
    <div className="rounded-md bg-slate-500 h-10 w-10"></div>
      <div className="flex-1 space-y-6 py-1">
        <div className="h-3 bg-slate-500 rounded"></div>
        <div className="space-y-3">
          <div className="grid grid-cols-3 gap-4">
            <div className="h-3 bg-slate-500 rounded col-span-2"></div>
            <div className="h-3 bg-slate-500 rounded col-span-1"></div>
          </div>
          {/* <div className="h-3 bg-slate-500 rounded"></div> */}
        </div>
      </div>
    </div>
  </div>
  ));

  if (space && space > 0) {
    return (
      <div className={`p-${space}`}>
         {htmls}
      </div>
    )
  }

  return (
    <>
      {htmls}
    </>
  )


}
