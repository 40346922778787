import styled from "styled-components"

import {ReactComponent as BankMoneySVG} from '../../assets/bankmoney.svg'

import {ReactComponent as ArrowPageRightIconSVG} from "../../assets/arrowPageRight.svg"
import {ReactComponent as ArrowPageLeftIconSVG} from "../../assets/arrowPageLeft.svg"

export const ArrowPageRightIcon = styled(ArrowPageRightIconSVG)`
fill: var(--gray);
`

export const ArrowPageLeftIcon = styled(ArrowPageLeftIconSVG)`
fill: var(--gray);
`

type ActionStatusProps = {
    status: "payed" | "pending" | "delayed"
}

const ActionStatusColors = {
    pending: '#F6BC4D',
    payed: '#49D294',
    delayed: '#E74C3C'
}

export const BankMoney = styled(BankMoneySVG)`
fill: var(--cian);
`

export const Container = styled.div`

height: 100vh;


`

export const Content = styled.div`

grid-area: content;
background-color: var(--background);
display: flex;
flex-direction: column;
padding: 32px;

`

export const CardsWrapper = styled.div`

padding: 32px 32px 0 32px;
gap: 32px;
display: grid;
grid-template-columns: 1fr;

@media (min-width: 720px){
    grid-template-columns: repeat(3, 1fr);

}

`

export const Table = styled.div`

flex: 1;
margin-top: 32px;

`


export const TableWrapper = styled.div`
  
  border-radius: 8px;
  background-color: var(--white);
  width: calc(100vw - 64px);
  overflow: auto;
  width: 100%;


  @media (min-width: 720px){
    width: calc(100vw - 64px - 256px);
  }

  @media (min-width: 1280px){
      width: 100%;
  }


> table {

    width: 100%;
    border-radius: 8px;
    border: none;
    border-collapse: collapse;

    > tr {
        position: relative;

        &:first-child {

            &::after {
                content: '';
                height: 16px;
                width: 4px;
                background-color: var(--cian);
                position: absolute;
                bottom: 0;
                top: 0;
                margin-top: auto;
                margin-bottom: auto;
                left: 0;
            }
        }

        border-bottom: 1px solid var(--gray-light-line);

        > th {
            text-align: start;
            font-family: var(--semiBold);
            font-size: 14px;
            color: var(--gray);
            padding: 16px;


            @media (min-width: 720px){
                font-size: 16px;
                padding: 21px 32px;
            }
        }

        > td {
            padding: 6px 16px;
            font-family: var(--regular);
            font-size: 10px;
            color: var(--gray-light-text);

                @media (min-width: 720px){
                    font-size: 14px;
                    padding: 12px 32px;

                }

                :first-child {
                    width: 50%;
                }
            
            }
          }
        }
    

`

export const ActionStatus = styled.div<ActionStatusProps>`

> span {
    background-color: ${props => ActionStatusColors[props.status]};
    padding: 4px 8px;
    color: var(--white);
    font-size: 12px;
    font-family: var(--bold);
    border-radius: 3px;
}

`

export const PaginationWrapper = styled.div`

display: flex;
align-items: center;
justify-content: space-between;
width: 280px;
padding: 32px;
align-self: center;

> span {
    font-family: var(--regular);
    color: var(--gray-light-text);
    font-size: 16px;
}

.active {
    height: 32px;
    width: 32px;
    background-color: var(--gray);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    border-radius: 16px;
}

`

export const WrapperHeader = styled.div`

display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;

@media (min-width: 720px){
    flex-direction: row;
}

`



export const ButtonWrapper = styled.div`

width: 100%;
margin-top: 32px;

@media (min-width: 720px){
    width: 228px;
    margin-top: 0;
}

`
