export function documentFormatted(val: string): string {
  if (val && val.length) {
    const docVal = val.replace(/[/,.,-]/g, "");

    if (docVal.length <= 14) {
      if (docVal.length > 12) {
        const cnpj =
          /^([\d]{2})\.*([\d]{3})\.*([\d]{3})\/*([\d]{4})\.*([\d]{2})/;
        return docVal.replace(cnpj, "$1.$2.$3/$4-$5");
      }
      const cpf = /([\d]{3})\.*([\d]{3})\.*([\d]{3})-*([\d]{2})/;
      return docVal.replace(cpf, "$1.$2.$3-$4");
    }
  }
  return val;
}
