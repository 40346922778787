import styled from "styled-components";
import {ReactComponent as ArrowPageLeftIconSVG} from "../../assets/arrowPageLeft.svg"


export const ArrowPageLeftIcon = styled(ArrowPageLeftIconSVG)`

fill: var(--cian);
width: 16px;

`

export const HeaderForm = styled.div`

width: 100%;
display: flex;
align-items: center;
margin-bottom: 44px;


`

export const GoBackButton = styled.button`

border-style: none;
margin-right: 24px;
height: 40px;
width: 40px;
background: var(--white);
border-radius: 8px;
display: flex;
align-items: center;
justify-content: center;
box-shadow: 0px 2px 15px -5px rgba(0, 0, 0, 0.2);
cursor: pointer;
flex-shrink: 0;

`

export const FormTitle = styled.h2`


font-size: 24px;
font-family: var(--bold);
color: var(--gray-dark);

`

export const BoxInfoWrapper = styled.div`
width: 480px;
background-color: var(--confirmBackground);
padding: 32px;
display: flex;
justify-content: space-between;
`



export const Container = styled.div`

min-height: calc(100vh - 80px);
background-color: var(--background);

`

export const Content = styled.div`

grid-area: content;
display: flex;
flex-direction: column;
padding: 32px;

`

export const OptionsWrapper = styled.div`

width: 100%;
padding: 32px;
display: flex;
flex-wrap: wrap;
justify-content: center;
gap: 32px;


@media (min-width: 900px){

    justify-content: start;

}

`

export const FormSubtitle = styled.span`

font-size: 16px;
font-family: var(--regular);
color: var(--gray-dark);

`

export const WrapperForm = styled.div``

export const BoxInfo = styled.div`

background: var(--white);
border-radius: 8px;
display: flex;
flex-direction: column;
justify-content: space-between;
box-shadow: 0px 2px 15px -5px rgba(0, 0, 0, 0.2);
height: 283px;
max-width: 488px;
padding: 32px;

`

export const Name = styled.strong`

font-size: 16px;
font-family: var(--semiBold);
color: var(--gray-dark);

`

export const Info = styled.div`

> strong {
font-size: 16px;
font-family: var(--semiBold);
color: var(--gray-dark);
}

> span {
font-size: 16px;
font-family: var(--regular);
color: var(--gray-dark);
}

`
export const WrapperHeader = styled.div`

margin-bottom: 44px;

`