import React from "react";
import {
  ActionPixTable,
  KeyCpnjIcon,
  KeyPixTable,
  ShareIcon,
  TrashIcon,
} from "../extract/styles";
import { Container, Content, LockIcon, MailIcon, Table, TableWrapper } from "./styles";

export const DepositPixMyKeys = () => {
  return (
    <Container>
      <Content>
        <Table>
          <TableWrapper>
            <table>
              <tr>
                <th>Data</th>
                <th>Chave</th>
                <th>Tipo de chave</th>
                <th>Descrição</th>
                <th>Ação</th>
              </tr>


                  <tr>
                    <td>27/02/2022</td>
                    <td>31.544.975/0001-13</td>
                    <td>
                      <KeyPixTable>
                        <KeyCpnjIcon />
                        <span>CPNJ</span>
                      </KeyPixTable>
                    </td>
                    <td>Moretti</td>
                    <td>
                      <ActionPixTable>
                        <ShareIcon />
                        <TrashIcon />
                      </ActionPixTable>
                    </td>
                  </tr>
                  
                  <tr>
                    <td>24/02/2022</td>
                    <td>comercial@moretti.com</td>
                    <td>
                      <KeyPixTable>
                        <MailIcon />
                        <span>E-mail</span>
                      </KeyPixTable>
                    </td>
                    <td>Moretti</td>
                    <td>
                      <ActionPixTable>
                        <ShareIcon />
                        <TrashIcon />
                      </ActionPixTable>
                    </td>
                  </tr>
                  <tr>
                    <td>21/02/2022</td>
                    <td>87498cre-654681-548468-bb3hy58hi</td>
                    <td>
                      <KeyPixTable>
                        <LockIcon />
                        <span>Chave Aleatória</span>
                      </KeyPixTable>
                    </td>
                    <td>Moretti</td>
                    <td>
                      <ActionPixTable>
                        <ShareIcon />
                        <TrashIcon />
                      </ActionPixTable>
                    </td>
                  </tr>
 
            </table>
          </TableWrapper>
        </Table>
      </Content>
    </Container>
  );
};
