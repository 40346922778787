import React from "react";
import { Button } from "../button";
import {
  ButtonWrapper,
  CloseIcon,
  InfoModal,
  Modal,
  ModalContent,
  ModalHeader,
  Value,
} from "./styles";

interface props {
  modalIsOpen: boolean;
  afterOpenModal: () => void;
  closeModal: () => void;
}

const ModalConfirmInvoiceOperation: React.FC<props> = ({
  afterOpenModal,
  closeModal,
  modalIsOpen,
}) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
    >
      <ModalHeader>
        <div>
          <span>Finalização</span>
          <button onClick={closeModal}>
            <CloseIcon />
          </button>
        </div>
      </ModalHeader>
      <ModalContent>

        <InfoModal>
            <span>Boleto de depósito emitido com sucesso!</span>
            <Value>
              <span>R$</span>
              <span>2.415,00</span>
            </Value>
        </InfoModal>

        <ButtonWrapper>
          <Button handleFunction={closeModal} title="Ok" />
        </ButtonWrapper>
      </ModalContent>
    </Modal>
  );
};

export default ModalConfirmInvoiceOperation;
