import { Children, createContext, useContext, useState } from "react";
import ModalConfirmInvoiceOperation from "../components/ModalConfirmInvoiceOperation";
import ModalConfirmOperation from "../components/ModalConfirmOperation";

interface ModalTransferContextProps {
  openInvoiceModal: () => void;
  closeInvoiceModal: () => void;
}

const modalInvoiceContext = createContext({} as ModalTransferContextProps);

export const ModalInvoiceContextProvider: React.FC = ({ children }) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  function openInvoiceModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeInvoiceModal() {
    setIsOpen(false);
  }

  return (
    <modalInvoiceContext.Provider
      value={{
        openInvoiceModal,
        closeInvoiceModal
      }}
    >
      <ModalConfirmInvoiceOperation
        modalIsOpen={modalIsOpen}
        afterOpenModal={afterOpenModal}
        closeModal={closeInvoiceModal}
      />
      {children}
    </modalInvoiceContext.Provider>
  );
};

export const useModalInvoice = () => {
    const context = useContext(modalInvoiceContext)
    return context
}
