import React, { ButtonHTMLAttributes } from 'react';

import { ArrowRightIcon, Container } from './styles';

interface props extends ButtonHTMLAttributes<HTMLButtonElement> {
    buttonName: string;
    isSelected?:boolean;
}

const FormSelectButton: React.FC<props> = ({
    buttonName,
    isSelected = false,
    ...rest
}) => {
  return (
      <Container {...rest} isSelected={isSelected} type='button' >
          <span>
              {
                  buttonName
              }
          </span>
          <ArrowRightIcon/>
      </Container>
  )
}

export default FormSelectButton;