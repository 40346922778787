import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/button";
import { PasswordInput } from "../../components/passwordInput";
import { StepHeader } from "../../components/stepHeader";
import { useModalTransfer } from "../../hooks/modalTransfer";

import {
  BoxInfoWrapper,
  BoxPasswordWrapper,
  ButtonsWrapper,
  Container,
  DataWrapper,
  Date,
  InfoWrapper,
  InvoiceCode,
  Key,
  LabelInput,
  Value,
  WrapperHeader,
} from "./styles";

export const PayBillsInvoiceConfirm: React.FC = () => {
  const navigation = useNavigate();
  const { openTransferModal } = useModalTransfer();

  const handleGoBackFunction = () => {
    navigation(-1);
  };

  const handleCancel = () => {
    navigation("/paybills");
  };

  const handleConfirm = () => {
    navigation("/paybills");
  };

  return (
    <Container>
      <WrapperHeader>
      <StepHeader
        handleGoBackFunction={handleGoBackFunction}
        title="Confirme e pague"
      />
      </WrapperHeader>
      <BoxInfoWrapper>
        <InfoWrapper>
          <Value>R$ 2.415,00</Value>
          <InvoiceCode>03324.1245.62374.124.125135235-2</InvoiceCode>
          <DataWrapper>
            <span>VENCIMENTO</span>
            <Key>02/03/2022</Key>
          </DataWrapper>
          <DataWrapper>
            <span>PAGAMENTO</span>
            <Key>02/03/2022</Key>
          </DataWrapper>
          <DataWrapper>
            <span>DESCRIÇÃO</span>
            <Key>-</Key>
          </DataWrapper>
        </InfoWrapper>
        <Date>09 FEV 2022 - 11:23</Date>
      </BoxInfoWrapper>
      <BoxPasswordWrapper>
        <LabelInput>Senha</LabelInput>
        <PasswordInput borderRadius="4px" borderColor="#E3E3E3" placeholder="********" />
      </BoxPasswordWrapper>
      <ButtonsWrapper>
        <Button
          handleFunction={handleCancel}
          color="#e3e3e3"
          title="Cancelar"
        />
        <Button handleFunction={handleConfirm} title="Continuar" />
      </ButtonsWrapper>
    </Container>
  );
};
