import styled, { css } from 'styled-components';

import {ReactComponent as ArrowRightIconSVG} from "../../assets/arrowRightDeposit.svg"

interface ButtonProps {
    isSelected: boolean;
}

export const ArrowRightIcon = styled(ArrowRightIconSVG)`
color: var(--cian);

`

export const Container = styled.button<ButtonProps>`

  ${props => !props.isSelected && css`
  
  opacity: 0.4;
  
  `}  

  width: 100%;
  min-height: 92px;
  background-color: var(--white);
  padding: 32px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-radius: 8px;
  border-style: none;
  transition: .2s all;

  > span {
      text-align: start;
      color: var(--gray);
      font-size: 16px;
      font-family: var(--semiBold);
  }
`;

