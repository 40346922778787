import React from "react";
import { Link } from "react-router-dom";
import { Container, IconWrapper } from "./styles";

interface IconTypeProps {
    width?: number;
    height?: number;
    color?: string;
  }

type IconType = (props: IconTypeProps) => JSX.Element;

type props = {
    title: string;
    Icon: IconType;
    isSelected?: boolean;
    linkTo?: string;
    onClick?: () => void;

  }

export const OptionSidebar: React.FC<props> = (
    {
    Icon,
    linkTo,
    title,
    isSelected,
    onClick,
    }
) => {



    return (
        <Container isSelected={isSelected} onClick={onClick}>
            <Link to={linkTo || ''}>
                <IconWrapper>
                    <Icon/>
                </IconWrapper>
                <span>{title}</span>
            </Link>
        </Container>
    )
}
