import styled from "styled-components";

import {ReactComponent as DepositIconSVG} from '../../assets/deposit.svg'
import {ReactComponent as PixIconSVG} from '../../assets/pix.svg'
import {ReactComponent as BillIconSVG} from '../../assets/billSide.svg'
import {ReactComponent as transferMoneyIconSVG} from '../../assets/transferSide.svg'

import {ReactComponent as searchIconSVG} from '../../assets/search.svg'


export const DepositIcon = styled(DepositIconSVG)`
fill: var(--cian);
`
export const PixIcon = styled(PixIconSVG)`
`
export const BillIcon = styled(BillIconSVG)`
`
export const transferMoneyIcon = styled(transferMoneyIconSVG)`
`

export const searchIcon = styled(searchIconSVG)`
`

export const Container = styled.div`

min-height: calc(100vh - 80px);
background-color: var(--background);

`

export const Content = styled.div`

grid-area: content;
display: flex;
flex-direction: column;

`

export const OptionsWrapper = styled.div`

width: 100%;
padding: 32px;
display: flex;
flex-wrap: wrap;
justify-content: center;
gap: 32px;


@media (min-width: 900px){

    justify-content: start;

}

`