import styled from "styled-components";

export const Container = styled.footer`
  width: 100%;
`;

export const ContentWrapper = styled.div`
  max-width: 1216px;
  margin: 0 auto;
  padding: 68px 20px;
`;

export const Nav = styled.nav`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 720px) {
    flex-direction: row;
  }
`;

export const AboutUs = styled.div`
  > p {
    max-width: 384px;
    text-decoration: none;
    color: var(--gray-1);
    font-size: 16px;
    margin-top: 32px;
    text-align: left;
  }

  > strong {
    text-decoration: none;
    color: var(--gray);
    font-size: 18px;
  }
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  width: 50%;

  @media (min-width: 720px) {
    margin-left: 64px;
    margin-top: 0px;
    width: auto;
  }
`;

export const OptionTitle = styled.strong`
  text-decoration: none;
  color: var(--gray);
  font-size: 18px;
  margin-bottom: 28px;
`;

export const Option = styled.a`
  text-decoration: none;
  color: var(--gray-1);
  font-size: 16px;
  display: flex;
  align-items: center;

  > svg {
    margin-right: 12px;
    color: var(--cian);
    fill: var(--cian);
  }

  & + a {
    margin-top: 10px;
  }
`;

export const Credits = styled.div`
  margin-top: 80px;

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  > span {
    font-size: 15px;
    color: var(--gray-1-lp);
    font-weight: var(--regular);
  }

  @media (min-width: 840px) {
    flex-direction: row;
  }
`;

export const By = styled.a`
  font-size: 12px;
  color: var(--gray-3-lp);
  font-weight: var(--regular);
  margin-top: 32px;

  > svg {
    margin-left: 12px;
  }

  @media (min-width: 840px) {
    margin-top: 0;
  }
`;

export const TextEndFooter = styled.p`
  margin-top: 32px;
  font-size: 14px;
  color: var(--gray-3-lp);
  font-weight: var(--regular);
  line-height: 22px;
  text-align: justify;
`;
