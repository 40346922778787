import { useState } from "react";
import { useAuth } from "../../hooks/auth";

export function SignoutPage() {
  const { signOut } = useAuth();

  useState(() => {
    signOut()
  });

  return null;
}
