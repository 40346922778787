import { Children, createContext, useContext, useState } from "react";
import ModalConfirmOperation from "../components/ModalConfirmOperation";

interface ModalTransferContextProps {
  openTransferModal: () => void;
  closeTransferModal: () => void;
}

const modalTransferContext = createContext({} as ModalTransferContextProps);

export const ModalTransferContextProvider: React.FC = ({ children }) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  function openTransferModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeTransferModal() {
    setIsOpen(false);
  }

  return (
    <modalTransferContext.Provider
      value={{
        openTransferModal,
        closeTransferModal
      }}
    >
      <ModalConfirmOperation
        modalIsOpen={modalIsOpen}
        afterOpenModal={afterOpenModal}
        closeModal={closeTransferModal}
      />
      {children}
    </modalTransferContext.Provider>
  );
};

export const useModalTransfer = () => {
  const context = useContext(modalTransferContext);
  return context;
};
