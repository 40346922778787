import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../components/button";
// import { PasswordInput } from "../../components/passwordInput";
import { StepHeader } from "../../components/stepHeader";
// import TextInput from "../../components/TextInput";
// import { useModalInvoice } from "../../hooks/modalInvoice";
import { dateFormat } from "../../utils/dateFormat";
import { documentFormatted } from "../../utils/documentFormatted";
import { normalizeName } from "../../utils/normelizaName";

import {
  BoxInfoWrapper,
  // BoxPasswordWrapper,
  ButtonsWrapper,
  Container,
  // DataWrapper,
  // Date,
  InfoWrapper,
  // InvoiceCode,
  // Key,
  // LabelInput,
  Name,
  Value,
  WrapperHeader,
  // WrapperInvoiceInfo,
} from "./styles";

interface StateProps {
  maskedValue: string;
  type: string
  ourNumber: string
  documentNumber: string
  amount: number
  dueAt: string;
  barCode: string
  digitableLine: string
  customerName: string
  customerDocument: string
  customerZipCode: string
  customerState: string
  url: string
  id: string
  date: string
  paidAt: string
}

export const DepositNewInvoiceConfirm: React.FC = () => {
  const navigation = useNavigate();
  // const { openInvoiceModal } = useModalInvoice();
  const location = useLocation();

  const state = location.state as StateProps;


  if (state === null) {
    navigation("/");
    return null;
  }

  const handleGoBackFunction = () => {
    navigation(-1);
  };

  // const handleCancel = () => {
  //   navigation("/paybills");
  // };

  const handleConfirm = () => {
    navigation("/charge");
  };


  return (
    <Container>
      <WrapperHeader>
      <StepHeader
        handleGoBackFunction={handleGoBackFunction}
        title="Cobrança gerada"
        subtitle="Confira os dados da cobrança"
      />
      </WrapperHeader>
      <BoxInfoWrapper>
        <InfoWrapper>
          <div className="flex gap-16">
         <div>
          <h3>Valor</h3>
            <Value>{state.maskedValue}</Value>
         </div>
          <div>
            <h3>Vencimento</h3>
            <Value>{dateFormat(state.dueAt)}</Value>
          </div>
         </div>

         <div className="mt-5">
            <h3>A ser pago por</h3>
            <Name>{normalizeName(state.customerName)}</Name> <br/>
            <Name>{documentFormatted(state.customerDocument)}</Name>
         </div>

         <div className="mt-5">
            <h3>Linha digitavel</h3>
            <Name>{state.digitableLine}</Name>
         </div>

         <div className="mt-5">
            <Button handleFunction={() => {
              window.open(state.url);
            }} title="Visualizar boleto" />
         </div>


          {/* <Name>A ser pago por {normalizeName(state.customerName)}</Name>

          <Name>Vencimento: {normalizeName(state.customerName)}</Name> */}
          {/* <WrapperInvoiceInfo>
            <div>
            <DataWrapper>
              <span>Documento</span>
              <Key>{documentFormatted(state.customerDocument)}</Key>
            </DataWrapper>
            <DataWrapper>
              <span>AGÊNCIA</span>
              <Key>0001</Key>
            </DataWrapper>
            </div>
            <div>
            <DataWrapper>
              <span>VENCIMENTO</span>
              <Key>{dateFormat(state.dueAt)}</Key>
            </DataWrapper>
            <DataWrapper>
              <span>Linha Digitável</span>
              <Key>1231245151-2</Key>
            </DataWrapper>
            </div>
          </WrapperInvoiceInfo> */}
        </InfoWrapper>
      </BoxInfoWrapper>
      <ButtonsWrapper>
        <Button
          handleFunction={handleConfirm}
          color="#A3A3A3"
          title="Voltar para cobranças"
        />
        {/* <Button handleFunction={handleConfirm} title="Voltar para cobranças" /> */}
      </ButtonsWrapper>
    </Container>
  );
};
