import styled from 'styled-components';
import {ReactComponent as ArrowIconSVG} from '../../assets/arrow.svg'

interface props {
   placeholderColor: string;
   borderColor: string;
}

export const ArrowIcon = styled(ArrowIconSVG)``

export const Container = styled.div`
   
   background-color: transparent;
   border-style: none;
   width: 100%;
   position: relative;

   & + div {
      margin-top: 16px;
   }
`;

export const Wrapper = styled.div`
   
   width: 100%;
   background-color: transparent;
   border-style: none;

`;

export const SelectedOption = styled.button<props>`

   background: none;
   height: 40px;
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   border: 1px solid ${props => props.borderColor};
   border-radius: 8px;
   padding: 16px 14px;

   > span {
      color: ${props => props.placeholderColor};
      font-size: 14px;
      font-family: var(--semiBold);
   }
`;

export const Options = styled.div`

margin-top: 16px;
position: absolute;
background-color: white;
width: 100%;
border: 1px solid var(--cian);
border-radius: 8px;

display: flex;
flex-direction: column;


`

export const Option = styled.button`

width: 100%;
background: none;
border-style: none;
padding: 16px 14px;
text-align: start;

> span {
      color: var(--cian);
      font-size: 14px;
      font-family: var(--semiBold);
   }

`