import ReactModal from 'react-modal';
import styled from 'styled-components';
import {ReactComponent as CloseIconSVG} from '../../assets/close.svg'
import {ReactComponent as arrowDownIconSVG} from '../../assets/arrowDown.svg'

export const ArrowDownIcon = styled(arrowDownIconSVG)`

`

export const CloseIcon = styled(CloseIconSVG)`

`

export const Modal = styled(ReactModal).attrs({
    style: {
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: '3',
          backgroundColor: 'rgba(0, 0, 0, 0.75)'
        },
        content: {
          display: "flex",
          flexDirection: 'column',
          maxHeight: '392px',
          maxWidth: '666px',
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          margin: 'auto',
          background: '#fff',
          // overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
        }
      }
})``

export const ModalWrapper = styled.div`

flex: 1;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
position: relative;

padding: 32px;
`

export const TitleModal = styled.span`
font-size: 24px;
font-family: var(--regular);
color: var(--cian);
margin-bottom: 36px;
`

export const DescriptionModal = styled.span`

margin-bottom: 73px;
text-align: center;
font-size: 16px;
font-family: var(--regular);
color: var(--modalTitle);

`

export const ModalButtonWrapper = styled.div`

margin-top: 54px;

`

export const CloseButtonModal = styled.button`

background: none;
border-style: none;
position: absolute;
top: 32px;
right: 32px;

`
export const InviteModal = styled.div`

display: flex;
flex-direction: column;
align-items: center;

`
export const LineModal = styled.div`

width: 384px;
height: 2px;
background: var(--modalTitle);


`
export const LeftInvitesModal = styled.div`

margin-top: 17px;
font-size: 16px;
font-family: var(--regular);
color: var(--modalTitle);

> span {
    color: var(--cian);

}

`