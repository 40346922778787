import styled from "styled-components";
import { ReactComponent as BillIconSVG } from "../../assets/billSide.svg";
import { ReactComponent as ArrowPageLeftIconSVG } from "../../assets/arrowPageLeft.svg";

export const ArrowPageLeftIcon = styled(ArrowPageLeftIconSVG)`
  fill: var(--cian);
  width: 16px;
`;

export const BillIcon = styled(BillIconSVG)`
  fill: var(--cian);
  width: 16px;
`;

export const Container = styled.div`
  height: 100vh;
`;

export const Content = styled.main`
  grid-area: content;
  display: flex;
  flex-direction: column;
  background-color: var(--background);
`;

export const Form = styled.form`
  max-width: 488px;
`;

export const HeaderForm = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 44px;
`;

export const GoBackButton = styled.button`
  border-style: none;
  margin-right: 24px;
  height: 40px;
  width: 40px;
  background: var(--white);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 15px -5px rgba(0, 0, 0, 0.2);
`;

export const FormTitle = styled.h2`
  font-size: 24px;
  font-family: var(--bold);
  color: var(--gray-dark);
`;

export const FormWrapper = styled.div`
  margin: 26px 0;
  max-width: 100%;
`;

export const SelectButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-bottom: 38px;
  gap: 32px;

  @media (min-width: 720px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const ContentWrapper = styled.div`
  padding: 32px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;

  @media (min-width: 720px) {
    width: 240px;
  }
`;

export const WrapperHeader = styled.div`

margin-bottom: 44px;

`
