import React, { forwardRef, ForwardRefRenderFunction, InputHTMLAttributes } from "react";
import IntlCurrencyInput from "react-intl-currency-input";

import { Container, Text } from "./styles";

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

interface IInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>  {
  onChange: (e: any, number: number, masked: string) => void;
}


const InputBase: ForwardRefRenderFunction<HTMLInputElement, IInputProps>
  = ({onChange,  ...rest}, ref) => {
  return (
    <Container>
    <Text>Valor</Text>
    <IntlCurrencyInput
      currency="BRL"
      config={currencyConfig}
      ref={ref}
      {...rest}
      onChange={onChange}
    />

  </Container>
  );
}

export const InputInsertValue = forwardRef(InputBase);

export default InputInsertValue;
