import React from "react";
import { Container } from "./styles";

type props = {
    title: string;
    value: string | number;
    color: string;
    textColor?: string;
}

export const Card: React.FC<props> = ({title, value, color, textColor = "#fff"}) => {


    return (
        <Container colorCard={color} textColor={textColor}>
            <p>{title}</p>
            <span>{value}</span>
        </Container>
    )
}