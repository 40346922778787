import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--white);
  display: flex;
  padding: 12px;
  border-radius: 8px;
  align-items: center;
  width: 100%;

  > input {
    flex: 1;
    font-size: 23px;
    color: var(--gray-dark);
    font-family: var(--semiBold);
    border-style: none;
    outline: none;
  }
`;

export const Text = styled.span`
  font-size: 16px;
  color: #505050;
  font-family: var(--regular);
  margin-right: 18px;
`;

export const Money = styled.span`
  font-size: 23px;
  color: var(--gray-dark);
  font-family: var(--semiBold);
  margin-right: 6px;
`;
