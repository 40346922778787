import styled from "styled-components";
import ReactModal from "react-modal";

import { ReactComponent as CloseIconSVG } from "../../assets/close.svg";
import { ReactComponent as DownloadIconSVG } from "../../assets/download.svg";

export const CloseIcon = styled(CloseIconSVG)`
fill: var(--white);
`

export const DownloadIcon = styled(DownloadIconSVG)`
fill: var(--white);
`

export const Modal = styled(ReactModal).attrs({
  style: {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: "3",
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      height: "280px",
      maxWidth: "448px",
      position: "absolute",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      margin: "auto",
      background: "#fff",
      // overflow: 'auto',
      WebkitOverflowScrolling: "touch",
      borderRadius: "4px",
      outline: "none",
    },
  },
})``;

export const ModalHeader = styled.header`
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  width: 100%;
  height: 72px;
  background: var(--gray);
  position: relative;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 28px 24px;

    > span {
      color: var(--white);
      font-family: var(--semiBold);
      font-size: 16px;
    }

    > button {
      cursor: pointer;
      background: none;
      border-style: none;
    }
  }

  &::before {
    content: "";
    height: 16px;
    width: 4px;
    background: var(--cian);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }
`;

export const InfoModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  > span {
    font-size: 16px;
    font-family: var(--regular);
    color: var(--modalTitle);
  }

`;

export const ModalContent = styled.div`
  padding: 32px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const Section = styled.div`
  margin: 32px 0;
`;

export const SectionTitle = styled.h2`
  color: var(--modalTitle);
  font-size: 16px;
  font-family: var(--bold);
  margin-bottom: 15px;
`;

export const SectionPayerName = styled.div`
  > span {
    color: var(--cian);
    font-size: 12px;
    font-family: var(--regular);
  }

  > p {
    color: var(--gray-light-text);
    font-size: 12px;
    font-family: var(--regular);
  }
`;

export const SectionItem = styled.div`
  display: flex;

  > span {
    color: var(--cian);
    font-size: 12px;
    font-family: var(--regular);
    margin-right: 9px;
  }

  > p {
    color: var(--gray-light-text);
    font-size: 12px;
    font-family: var(--regular);
  }
`;

export const SectionCPNJ = styled.div`
  display: flex;

  > span {
    color: var(--cian);
    font-size: 12px;
    font-family: var(--regular);
    margin-right: 9px;
  }

  > p {
    color: var(--gray-light-text);
    font-size: 12px;
  }
`;

export const SectionAccountinfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
`;

export const ButtonWrapper = styled.div`

width: 192px;
display: flex;
justify-content: center;

`

export const Value = styled.div`

margin-top: 33px;
font-size: 29px;
color: var(--gray);
font-family: var(--semiBold);

`