import React from "react";
import { CardActionOption } from "../../components/cardActionOption";

import {
  BillIcon,
  Container,
  Content,
  OptionsWrapper,
  // ScheduleIcon,
} from "./styles";

export const PayBills: React.FC = () => {
  return (
    <Container>
      <Content>
        <OptionsWrapper>
          <CardActionOption
            linkTo="invoice"
            Icon={BillIcon}
            title={`Pagar Contas`}
          />
          {/* <CardActionOption
            linkTo="schedule-payments"
            Icon={ScheduleIcon}
            title={"Consultar Pagamentos Agendados"}
          /> */}
        </OptionsWrapper>
      </Content>
    </Container>
  );
};
