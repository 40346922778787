import React from "react";
import { useNavigate } from "react-router-dom";
import { StepHeader } from "../../components/stepHeader";
import { useAuth } from "../../hooks/auth";
import { useClient } from "../../hooks/client";
import { documentFormatted } from "../../utils/documentFormatted";
import {
  Container,
  Content,
  HeaderForm,
  GoBackButton,
  ArrowPageLeftIcon,
  FormTitle,
  FormSubtitle,
  WrapperForm,
  BoxInfo,
  Name,
  Info,
  WrapperHeader
} from "./styles";

export const DepositInfoTedDoc = () => {
  const { account } = useAuth();
  const { client } = useClient();

  const navigate = useNavigate()
  const handleGoBack = () => {
      navigate(-1)
  }

  return (
    <Container>
      <Content>
        <WrapperHeader>

        <StepHeader handleGoBackFunction={handleGoBack} title="Dados para transferência"  subtitle={`Use os dados abaixo para fazer um TED ou DOC para sua conta ${client.name}`}/>

        </WrapperHeader>
    <BoxInfo>
      <Name>
        {account.name.toUpperCase()}
      </Name>
      <Info>
      <strong>CNPJ: </strong>
      <span>{documentFormatted(account.document)}</span>
      </Info>
     {!account.isPool && (
      <>
        <Info>
        <strong>Banco: </strong>
        <span>{account.bank}</span>
        </Info>
        <Info>
        <strong>Agência: </strong>
        <span>{account.branch}</span>
        </Info>
      </>
     )}
      <Info>
      <strong>Conta: </strong>
      <span>{account.account}-{account.accountDigit}</span>
      </Info>
    </BoxInfo>
      </Content>
    </Container>
  );
};
