import styled from 'styled-components';
import {ReactComponent as BillIconSVG} from '../../assets/billSide.svg'
import {ReactComponent as arrowDownIconSVG} from '../../assets/arrowDown.svg'
import {ReactComponent as ArrowRightIconSVG} from "../../assets/arrowRightDeposit.svg"


export const ArrowRightIcon = styled(ArrowRightIconSVG)`

`


export const BillIcon = styled(BillIconSVG)`

fill: var(--cian);
width: 25px;

`


export const Container = styled.div`

height: calc(100vh - 80px);
background-color: var(--background);


`

export const Content = styled.main`

grid-area: content;
display: flex;
flex-direction: column;


`

export const ContentWrapper = styled.main`

padding: 32px;

`

export const Box = styled.main`

background-color: var(--white);
padding: 32px;
border-radius: 8px;
max-width: 592px;

display: flex;
flex-direction: column;
justify-content: space-between;

`


export const BoxText = styled.div`

> h2 {
    font-size: 16px;
    font-family: var(--semiBold);
    color: var(--gray);
}

> span {
    font-size: 12px;
    font-family: var(--regular);
    color: var(--gray-light-text);
}

`

export const BoxInput = styled.div`

display: flex;
flex-direction: column;
margin-top: 57px;

@media (min-width: 720px){
    flex-direction: row;
    align-items: center;

}

`

export const InputWrapper = styled.div`

display: flex;
align-items: center;
border-bottom: 1px solid var(--gray);
flex: 1;

> input {
    padding: 18px;
    width: 100%;
    border-style: none;
    outline: none;
    ::placeholder {
        font-size: 12px;
        font-family: var(--regular);
        color: var(--gray-light-text);
    }
}

`

export const BoxButton = styled.div`

margin-top: 32px;

@media (min-width: 720px){
    margin-top: 0px;

    margin-left: 32px;
}

`

export const Button = styled.button`

cursor: pointer;
background: ${props => props.color ? props.color : 'var(--green)'};
height: 40px;
width: 100%;
padding: 0 32px;
border-style: none;
align-items: center;
justify-content: center;
border-radius: 4px;
display: flex;

> p {
    color: var(--white);
    font-family: var(--semiBold);
    font-size: 14px;
}

> svg {
    margin-left: 8px;
    color: var(--white);
    height: 9px;
}

`