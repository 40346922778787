import React, { useState } from 'react';

import { NumberToken } from '../../components/numberToken';

import { ButtonSignIn, Container, Content,  WhereIsMyToken, Reference, Separator, SeparatorNumbers, StricLogo, Title, WrapperBackground, WrapperContent, WrapperForm, WrapperLogo, WrapperToken } from './styles';

export const FillOutToken: React.FC = () => {

  // const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  // const handlePasswordVisibility = () => {
  //   setIsPasswordVisible(state => !state)
  // }

  const [valueInputOne, setValueInputOne] = useState('')

  const handleInputEntry = (e: any) => {
    if(e.target.value){
      if(valueInputOne.length > 1 || valueInputOne.length === 1){
        setValueInputOne('')
        setValueInputOne(e.target.value)
      } else {
        setValueInputOne(e.target.value)
      }

      // console.log(valueInputOne)
    }
  }

  return (
      <Container>
          <WrapperBackground/>
          <WrapperContent>
            <Content>
            <WrapperLogo>
            <StricLogo/>

            </WrapperLogo>
            <Title>
              Preencha o código <br /> do seu token digital
            </Title>
            <Separator/>
            <Reference>
            Preencha os campos abaixo para acessar sua conta
            </Reference>
            <WrapperForm>
              <WrapperToken>
                <SeparatorNumbers>
                  <NumberToken
                  value={valueInputOne} handleFunctionInput={handleInputEntry}/>
                  <NumberToken/>
                  <NumberToken/>
                </SeparatorNumbers>
                <SeparatorNumbers>
                  <NumberToken/>
                  <NumberToken/>
                  <NumberToken/>
                </SeparatorNumbers>
              </WrapperToken>
              <ButtonSignIn>
              Completar Login
              </ButtonSignIn>
            </WrapperForm>
            <WhereIsMyToken href='#'>
              Onde está o token?
            </WhereIsMyToken>
            </Content>
          </WrapperContent>
      </Container>
  )
}
