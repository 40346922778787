import React from "react";
import { useNavigate } from "react-router-dom";

import {
  ArrowPageLeftIcon,
  FormSubtitle,
  FormTitle,
  GoBackButton,
  HeaderForm,
  WrapperForm,
} from "./styles";

interface props {
  title: string;
  subtitle?: string;
  handleGoBackFunction: () => void;
}

export const StepHeader: React.FC<props> = ({
  handleGoBackFunction,
  title,
  subtitle
}) => {


  return (
    <HeaderForm>
      <GoBackButton onClick={handleGoBackFunction}>
        <ArrowPageLeftIcon />
      </GoBackButton>
      <WrapperForm>
      <FormTitle>{title}</FormTitle>
      {
        subtitle && <FormSubtitle>
        {subtitle}
        </FormSubtitle>
      }
      </WrapperForm>

    </HeaderForm>
  );
};
