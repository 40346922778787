import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/button";
import ModalConfirmOperation from "../../components/ModalConfirmOperation";
import { useModalTransfer } from "../../hooks/modalTransfer";

import {
  Action,
  ButtonWrapper,
  ButtonWrapperTable,
  Container,
  Content,
  ContentWrapper,
  InputWrapper,
  RedirectWrapperButton,
  Search,
  Table,
  TableWrapper,
  TrashIcon,
  ArrowRightDepositIcon,
  ButtonsTableWrapper,
} from "./styles";

export const PixList: React.FC = () => {
  const navigate = useNavigate();

  const handleNextScreen = () => {
    navigate("/pix/transfer");
  };

  return (
    <>
      <Container>
        <Content>
          <ContentWrapper>
            <Action>
              <Search>
                <h1 className="text-2xl">Pix</h1>
                <span>
                Selecione o contato ou cadastre um novo para realizar a transferência
                </span>
                {/* <InputWrapper>
                  <input
                    type="text"
                    placeholder="Pesquisar por Nome, Conta ou CNPJ"
                  />
                </InputWrapper> */}
              </Search>
              <ButtonWrapper>
                <Button
                  handleFunction={handleNextScreen}
                  title="Nova transferência"
                />
              </ButtonWrapper>
            </Action>
            <Table>
              <TableWrapper>

              <div className="bg-transparent p-4">
                <div className="text-center bg-transparent">
                  <h3 className="bg-transparent mt-2 text-sm font-medium text-gray-900">Nenhum Favorecido</h3>
                  <p className="bg-transparent mt-1 text-sm text-gray-500">Você ainda não cadastrou nenhum favorecido</p>
                  <div className="bg-transparent mt-6">
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Nova transferência
                    </button>
                  </div>
                </div>
              </div>


                {/* <table>
                  <tbody>
                    <tr>
                      <th>Nome</th>
                      <th>Chave</th>
                      <th>Ação</th>
                    </tr>

                    {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => {
                      return (
                        <tr key={item}>
                          <td>Rafael Juan Sales</td>
                          <td>387.376.273-71</td>
                          <td>
                            <ButtonsTableWrapper>
                              <ButtonWrapperTable>
                                <TrashIcon />
                              </ButtonWrapperTable>
                              <RedirectWrapperButton>
                                <span>Transferir</span>
                                <ArrowRightDepositIcon />
                              </RedirectWrapperButton>
                            </ButtonsTableWrapper>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table> */}
              </TableWrapper>
            </Table>
          </ContentWrapper>
        </Content>
      </Container>
    </>
  );
};
