import { createContext, useContext, useState } from "react";
import ModalConfirmPixOperation from "../components/ModalConfirmPixOperation";

interface ModalPixContextProps {
  openPixModal: () => void;
  closePixModal: () => void;
}

const modalPixContext = createContext({} as ModalPixContextProps);

export const ModalPixContextProvider: React.FC = ({ children }) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  function openPixModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closePixModal() {
    setIsOpen(false);
  }

  return (
    <modalPixContext.Provider
      value={{
        openPixModal,
        closePixModal
      }}
    >
      <ModalConfirmPixOperation
        modalIsOpen={modalIsOpen}
        afterOpenModal={afterOpenModal}
        closeModal={closePixModal}
      />
      {children}
    </modalPixContext.Provider>
  );
};

export const useModalPix = () => {
    const context = useContext(modalPixContext)
    return context
}
