import React, { useState } from 'react';
import { Button } from '../button';
import { CloseButtonModal, CloseIcon, DescriptionModal, Modal, ModalWrapper, TitleModal, InviteModal, LineModal, LeftInvitesModal, ModalButtonWrapper, ArrowDownIcon } from './styles';

// import { Container } from './styles';

interface props {
    isOpen: boolean;
    handleClose: () => void
}

const ModalReference: React.FC<props> = ({
    isOpen,
    handleClose
}) => {


    let subtitle = {
        style: {
            color: ''
        }
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#000';
      }
    
      

  return (
    <Modal isOpen={isOpen}>
        <ModalWrapper>
            <CloseButtonModal onClick={handleClose} >
                <CloseIcon/>
            </CloseButtonModal>
            <TitleModal>
            Ajude outras empresas a saírem dos bancões! 
            </TitleModal>
            <DescriptionModal>
            Convide empresas que ainda não usam a Stric. <br />
            A empresa indicada receberá um convite exclusivo para se cadastrar. 
            </DescriptionModal>
            <InviteModal>
                <LineModal/>
                <LeftInvitesModal>
                Você tem <span>10 convites </span>
                </LeftInvitesModal>
            </InviteModal>
            <ModalButtonWrapper>
                <Button Icon={ArrowDownIcon} title="Compartilhar via e-mail" />
            </ModalButtonWrapper>
        </ModalWrapper>
    </Modal>
    )
}

export default ModalReference;