import React from "react";
import { Container, ServiceButton, ServiceHeader, ServiceTitle } from "./styles";

interface IconTypeProps {
    width?: number;
    height?: number;
    color?: string;
  }  

type IconType = (props: IconTypeProps) => JSX.Element;

interface Props {
    title: string;
    Icon: IconType;
    handleOption: () => void;
    isOpen: boolean;
  }


export const Service: React.FC<Props> = ({isOpen, Icon, title, handleOption, children}) => {


    return (
        <Container onClick={handleOption}>
            <ServiceHeader>
                <ServiceTitle>
                        {title}
                </ServiceTitle>
                <ServiceButton isOpen={isOpen}>
                    <Icon/>
                </ServiceButton>
            </ServiceHeader>
        </Container>
    )
}