import React, { ButtonHTMLAttributes } from "react";
import { Loading } from "../loading";
// import { Icon } from "../sidebar/styles";
import { Container } from "./styles";

interface IconTypeProps {
    width?: number;
    height?: number;
    color?: string;
  }

type IconType = (props: IconTypeProps) => JSX.Element;

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    title: string;
    Icon?: IconType;
    handleFunction?: () => void;
    color?: string;
    isLoading?: boolean;
  }

export const Button: React.FC<Props> = ({
    Icon,
    type= 'button',
    title,
    color,
    handleFunction,
    isLoading
}) => {

    return (
        <Container type={type} onClick={handleFunction} color={color} disabled={isLoading}>
            {
                Icon && <Icon/>
            }
            {isLoading ? <Loading isLoading={isLoading} /> : null}
            <p>{title}</p>
        </Container>
    )
}
