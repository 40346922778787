import styled from "styled-components";
import { ReactComponent as BillIconSVG } from "../../assets/billSide.svg";
import { ReactComponent as SentIconSVG } from "../../assets/sent.svg";
import { ReactComponent as StricIconSVG } from "../../assets/stric.svg";

export const BillIcon = styled(BillIconSVG)`
  fill: var(--cian);
`;

export const StricIcon = styled(StricIconSVG)`
  fill: var(--cian);
`;

export const SentIcon = styled(SentIconSVG)`
  fill: var(--cian);
`;

export const Container = styled.div`
  min-height: calc(100vh - 80px);
  background-color: var(--background);
`;

export const Content = styled.main`
  grid-area: content;
  display: flex;
  flex-direction: column;
  padding: 32px;

`;

export const OptionsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  @media (min-width: 720px) {
    justify-content: start;
    flex-direction: row;
  }
`;

export const Text = styled.div`
margin-top: 49px;
  > span {
    font-size: 16px;
    color: var(--modalTitle);
    font-family: var(--regular);
  }
`;
