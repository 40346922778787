import React, { useEffect, useState } from "react";
import {  endOfMonth, isBefore, startOfMonth } from "date-fns";
import { Card } from "../../components/card";
import { Skeleton } from "../../components/skeleton";
import { api } from "../../services/api";
import { currencyFormat } from "../../utils/currencyFormat";
import { dateFormat } from "../../utils/dateFormat";

import {
  Container,
  Content,
  CardsWrapper,
  Table,
  TableWrapper,
  ActionStatus,
  PaginationWrapper,
  ArrowPageRightIcon,
  ArrowPageLeftIcon,
} from "./styles";

interface IItems {
  type: string
  ourNumber: string
  documentNumber: string
  amount: number
  dueAt: string;
  barCode: string
  digitableLine: string
  customerName: string
  customerDocument: string
  customerZipCode: string
  customerState: string
  url: string
  id: string
  date: string
  paidAt: string
}

interface PropsStatus {
  title: string;
  label: "payed" | "pending" | "delayed";
}

export const Charge = () => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<IItems[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
    useEffect(() => {
      setLoading(true);
      const start = startOfMonth(new Date());
      const end = endOfMonth(new Date());

      api.get(`/billets?page=${currentPage}&start=${start}&end=${end}`).then(({data}) => {
        setTotalPages(data.total_pages + 1);
        setCurrentPage(data.current_page);
        setItems(data.items);
        setLoading(false);
      });
    }, [currentPage]);

    function handlePrevious() {
      if (currentPage + 1  > 1) {
        setCurrentPage(currentPage - 1);
      }
    }

    function handleNext() {
      if (currentPage + 1  < totalPages) {
        setCurrentPage(currentPage + 1);
      }
    }

    function getStatus(billet: IItems): PropsStatus {

      if (!billet.paidAt) {
        const now = new Date();
        if (isBefore(new Date(billet.dueAt), now)) {
          return {
            title: 'Atrasado',
            label: 'delayed',
          }
        }
      }

      if (billet.paidAt) {
        return {
          title: 'Pago',
          label: 'payed',
        }
      }

      return {
        title: "Em Aberto",
        label: "pending"
      }
    }

  return (
    <Container>
      <Content>
        <CardsWrapper>
          <Card title="Pagos" color="#49D294" value="***" />
          <Card title="Atrasados" color="#E74C3C" value="***" />
          <Card title="Pendentes" color="#F6BC4D" value="***" />
        </CardsWrapper>
        <Table>
          <TableWrapper>
          {!loading && items.length <= 0 && (
            <div className="p-6">
            <div className="text-center">
              <h3 className="mt-2 text-sm font-medium text-gray-900">Nenhum lançamento para o período selecionado</h3>
              <p className="mt-1 text-sm text-gray-500">Selecione outro período para a busca</p>

            </div>
          </div>
            )}
            <Skeleton space={4} isActive={loading} repeat={5} />
            <table>
            {!loading && items.length > 0 && (
              <thead>
                <tr>
                  <th>Vencimento</th>
                  <th>Pagador</th>
                  <th>Status</th>
                  <th>Valor</th>
                </tr>
              </thead>
              )}

              <tbody>
              {!loading && items.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>{dateFormat(item.dueAt)}</td>
                    <td>{item.customerName}</td>
                    <td>
                      <ActionStatus status={getStatus(item).label}>
                        <span>{getStatus(item).title}</span>
                      </ActionStatus>
                    </td>
                    <td>{currencyFormat(item.amount)}</td>
                  </tr>
                );
              })}
              </tbody>
            </table>
          </TableWrapper>
        </Table>
        <PaginationWrapper>
            <ArrowPageLeftIcon onClick={handlePrevious}  />
            <span>Página {currentPage  + 1} de {totalPages}</span>
            <ArrowPageRightIcon onClick={handleNext} />
          </PaginationWrapper>
      </Content>
    </Container>
  );
};
