import React from "react";
import { CardActionOption } from "../../components/cardActionOption";
import {
  BillIcon,
  Container,
  Content,
  OptionsWrapper,
  PixIcon,
  searchIcon,
  transferMoneyIcon,
} from "./styles";

export const Deposit = () => {
  return (
    <Container>
      <Content>
        <OptionsWrapper>
          <CardActionOption
            linkTo="/pix/mykeys"
            Icon={PixIcon}
            title={`Depósito por Pix`}
          />
          <CardActionOption
            Icon={transferMoneyIcon}
            title={"Depósito por TED ou DOC"}
            linkTo="tedordoc"
          />
          <CardActionOption
            linkTo="invoice"
            Icon={BillIcon}
            title={"Depósito por Boleto"}
          />
          {/* <CardActionOption
            linkTo="invoices-list"
            Icon={searchIcon}
            title={"Listar boleto De depósito"}
          /> */}
        </OptionsWrapper>
      </Content>
    </Container>
  );
};
