import styled from 'styled-components';
import {ReactComponent as BillIconSVG} from '../../assets/billSide.svg'
import {ReactComponent as ScheduleIconSVG} from '../../assets/schedule.svg'

export const BillIcon = styled(BillIconSVG)`

fill: var(--cian);
width: 16px;

`

export const ScheduleIcon = styled(ScheduleIconSVG)``

export const Container = styled.div`

background-color: var(--background);
min-height: calc(100vh - 80px);

`

export const Content = styled.main`

grid-area: content;
display: flex;
flex-direction: column;


`

export const OptionsWrapper = styled.div`

width: 100%;
padding: 32px;
display: flex;
flex-wrap: wrap;
justify-content: center;
gap: 32px;


@media (min-width: 900px){

    justify-content: start;

}

`