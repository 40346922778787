import styled from "styled-components";

import {ReactComponent as ArrowIconSVG} from '../../assets/arrow.svg'
import {ReactComponent as TransferMoneySVG} from '../../assets/transferMoney.svg'

export const Container = styled.div`

grid-area: contacts;
background-color: var(--white);
border-radius: 8px;
display: flex;
flex-direction: column;

`

export const HeaderWrapper = styled.div`

display: flex;
justify-content: space-between;
border-bottom: 1px solid var(--gray-light-line);
padding: 24px 32px;

> span {
    color: var(--gray);
    font-size: 16px;
    font-family: var(--semiBold);
}

`

export const Header = styled.div`

position: relative;

&::after{
    content: '';
    width: 4px;
    height: 16px;
    background-color: var(--cian);
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
}


`

export const ArrowIcon = styled(ArrowIconSVG)``
export const TransferMoney = styled(TransferMoneySVG)``


export const ContactsWrapper = styled.div`

display: flex;
flex-direction: column;

padding: 32px;
display: flex;


`

export const Contact = styled.div`

display: flex;
align-items: center;
justify-content: space-between;
width: 100%;


& + div {
    margin-top: 16px;
}

`

export const ContactInfo = styled.div`

> span {
    font-size: 14px;
    color: var(--gray);
    font-family: var(--regular);
}

> p {
    font-size: 14px;
    color: var(--gray-light-text);
    font-family: var(--regular);
}

`
