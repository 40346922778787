import React from "react";
import { Button } from "../button";
import {
  ButtonWrapper,
  CloseIcon,
  DownloadIcon,
  InfoModal,
  InfoTransaction,
  Modal,
  ModalContent,
  ModalHeader,
  Section,
  SectionItem,
  SectionTitle,
} from "./styles";

interface props {
  modalIsOpen: boolean;
  afterOpenModal: () => void;
  closeModal: () => void;
}

const ModalPixConfirmOperation: React.FC<props> = ({
  afterOpenModal,
  closeModal,
  modalIsOpen,
}) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
    >
      <ModalHeader>
        <div>
          <span>Transferência realizada com sucesso!</span>
          <button onClick={closeModal}>
            <CloseIcon />
          </button>
        </div>
      </ModalHeader>
      <ModalContent>
        <InfoModal>
          <div>
            <span>Henrique Sabino dos Santos</span>
            <p>R$ 2.415,00</p>
          </div>
          <span>09 FEV 2022 - 11:23</span>
        </InfoModal>
        <InfoTransaction>
        <Section>
          <SectionTitle>Pagamento</SectionTitle>
          <SectionItem>
            <span>NOME</span>
            <p>
              Henrique Sabino dos Santos
            </p>
          </SectionItem>
          <SectionItem>
            <span>TIPO</span>
            <p>CELULAR</p>
          </SectionItem>
          <SectionItem>
            <span>CHAVE</span>
            <p>12312515164</p>
          </SectionItem>
        </Section>
        </InfoTransaction>
        <ButtonWrapper>
          <Button handleFunction={closeModal} Icon={DownloadIcon} title="Baixar Comprovante" />
        </ButtonWrapper>
      </ModalContent>
    </Modal>
  );
};

export default ModalPixConfirmOperation;
