import {ReactComponent as VisionIconSVG} from '../../assets/vision.svg'
import {ReactComponent as HomeIconSVG} from '../../assets/home.svg'
import {ReactComponent as ReceiptIconSVG} from '../../assets/receipt.svg'
import {ReactComponent as BankMoneySVG} from '../../assets/bankmoney.svg'
import {ReactComponent as ArrowIconSVG} from '../../assets/arrow.svg'
import {ReactComponent as signOutIconSVG} from '../../assets/sign-out.svg'
import {ReactComponent as HelpIconSVG} from '../../assets/help.svg'
import {ReactComponent as AccountDeclarationIconSVG} from '../../assets/accountDeclaration.svg'
import {ReactComponent as TaxIconSVG} from '../../assets/tax.svg'
import {ReactComponent as BillIconSVG} from '../../assets/billSide.svg'
import {ReactComponent as PixIconSVG} from '../../assets/pix.svg'
import {ReactComponent as ChargeIconSVG} from '../../assets/chargeSide.svg'
import {ReactComponent as DepositIconSVG} from '../../assets/deposit.svg'
import {ReactComponent as transferMoneyIconSVG} from '../../assets/transferSide.svg'
import {ReactComponent as ShareIconSVG} from '../../assets/share.svg'
import styled, { css } from 'styled-components'

const iconStyle = css`

fill: var(--cian);
width: 16px;

`

export const Container = styled.div`

> svg {
    fill: var(--cian);
    width: 16px; 
}

`


