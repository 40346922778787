import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/button";

import {
  Action,
  ButtonWrapper,
  Container,
  Content,
  ContentWrapper,
  InputWrapper,
  RedirectWrapperButton,
  Search,
  Table,
  TableWrapper,
  ArrowRightDepositIcon,
  ButtonsTableWrapper,
} from "./styles";

export const PayBillsSchedulePayments: React.FC = () => {


  const navigate = useNavigate()
  const handleNextScreen = () => {

    navigate('/paybills/subscribe')

  }


  return (
    <Container>
      <Content>
        <ContentWrapper>
          <Action>
            <Search>
              <span>
                Transferência solicitada após às 15:00 só será processada no
                próximo dia útil. Número de transferências sem taxa
                remanescentes: 2/2
              </span>
              <InputWrapper>
                <input
                  type="text"
                  placeholder="Pesquisar por Nome, Conta ou CNPJ"
                />
              </InputWrapper>
            </Search>
            <ButtonWrapper>
              <Button handleFunction={handleNextScreen} title="Cadastrar Favorecido" />
            </ButtonWrapper>
          </Action>
          <Table>
            <TableWrapper>
              <table>
                <tbody>
                  <tr>
                    <th>Código</th>
                    <th>Vencimento</th>
                    <th>Data do Pagamento</th>
                  </tr>

                  {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => {
                    return (
                      <tr key={item}>
                        <td>03324.1245.62374.124.125135235-2</td>
                        <td>10/03/2022</td>
                        <td>08/03/2022</td>
                        <td>
                          <ButtonsTableWrapper>
                            <RedirectWrapperButton>
                              <span>Pagar agora</span>
                              <ArrowRightDepositIcon />
                            </RedirectWrapperButton>
                          </ButtonsTableWrapper>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </TableWrapper>
          </Table>
        </ContentWrapper>
      </Content>
    </Container>
  );
};
