import styled from "styled-components"

type cardProps = {
    colorCard: string;
    textColor: string;
}

export const Container = styled.div<cardProps>`

flex: 1;
height: 90px;
border-radius: 8px;
padding: 14px 16px;
display: flex;
flex-direction: column;
justify-content: space-between;
position: relative;

&::after {
    content: '';
    position: absolute;
    width: 3px;
    height: 16px;
    background-color: rgba(0, 0, 0, 0.3);
    left: 0;
}

> p {
    font-family: var(--light);
    font-size: 16px;

    @media (min-width: 720px){
        font-size: 12px;
        }
    }

    > span {
        font-family: var(--semiBold);
        font-size: 32px;

        @media (min-width: 720px){
            font-size: 24px;
        }
    }

@media (min-width: 720px){
    height: 128px;
}

@media (min-width: 1000px){
    
    > p {
    font-family: var(--light);
    font-size: 14px;

    }
    > span {
        font-family: var(--semiBold);
        font-size: 28px;
    }
}

@media (min-width: 1280px){
    
    > p {
    font-family: var(--light);
    font-size: 16px;

    }
    > span {
        font-family: var(--semiBold);
        font-size: 32px;
    }
}

background-color: ${props => props.colorCard};
color: ${props => props.textColor};

`